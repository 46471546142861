import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { SiGooglemaps } from 'react-icons/si';
import { MdLocalPhone, MdOutlineEmail } from 'react-icons/md';
import { PiLineVertical } from 'react-icons/pi';
import { BsWhatsapp } from 'react-icons/bs';

import s from './Footer.module.scss';

const Footer = () => {
  const { t } = useTranslation();
  const isDesctop = useMediaQuery({ minWidth: 1280 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [basePath, setBasePath] = useState(`/${i18n.language}`);

  useEffect(() => {
    const handleLanguageChange = () => {
      setBasePath(`/${i18n.language}`);
    };
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [basePath]);

  return (
    <footer className={s.footer}>
      <div className="container">
        <div className={s.footer__content}>
          <div className={s.footer__partLeft}>
            <span className={s.footer__partTitle}>Art Studio Dental</span>
            <Link className={s.footer__link} to={`${basePath}/contacts`}>
              <div className={s.footer__iconContainer}>
                <SiGooglemaps color="white" size={isDesctop ? 17 : 15} />
              </div>
              <div className={s.footer__text}>
                <address>
                  Calle Bruselas, 10, isla IV, 43-C, Playa D'Aro, Girona, 17250,
                  Spain
                </address>
              </div>
            </Link>
            <a className={s.footer__link} href="tel:+34614258852">
              <div className={s.footer__iconContainer}>
                <MdLocalPhone color="white" size={isDesctop ? 17 : 15} />
              </div>
              <span className={s.footer__text}>+34 614 258 852</span>
            </a>
            <a
              className={s.footer__link}
              href="https://wa.me/+34614258852"
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className={s.footer__iconContainer}>
                <BsWhatsapp color="white" size={isDesctop ? 17 : 15} />
              </div>
              <span className={s.footer__text}>WhatsApp</span>
            </a>
            <a
              className={s.footer__link}
              href="mailto:artstudiodental1@gmail.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className={s.footer__iconContainer}>
                <MdOutlineEmail color="white" size={isDesctop ? 19 : 17} />
              </div>
              <span className={s.footer__text}>
                Email: artstudiodental1@gmail.com
              </span>
            </a>
            <div className={s.footer__partS}>
              <span className={s.footer__partTitle}>{t('Schedule')}</span>
              <span className={s.footer__textP}>
                {t('Mon-Sun: 9:00 — 21:00')}
              </span>
            </div>
          </div>
          <div className={s.footer__partRight}>
            <span className={s.footer__partTitle}>{t('Services')}</span>
            <Link
              className={s.footer__link}
              to={`${basePath}/services#dental-treatment`}
            >
              <span className={s.footer__textS}>{t('Dental treatment')}</span>
            </Link>
            <Link
              className={s.footer__link}
              to={`${basePath}/services#hygiene`}
            >
              <span className={s.footer__textS}>
                {t('Hygiene and whitening')}
              </span>
            </Link>
            <Link
              className={s.footer__link}
              to={`${basePath}/services#prosthetics`}
            >
              <span className={s.footer__textS}>{t('Prosthetics')}</span>
            </Link>
            <Link
              className={s.footer__link}
              to={`${basePath}/services#implantology`}
            >
              <span className={s.footer__textS}>{t('Implantology')}</span>
            </Link>
            <Link
              className={s.footer__link}
              to={`${basePath}/services#orthodontics`}
            >
              <span className={s.footer__textS}>{t('Orthodontics')}</span>
            </Link>
            <Link
              className={s.footer__link}
              to={`${basePath}/services#ct-scan`}
            >
              <span className={s.footer__textS}>{t('CT scan')}</span>
            </Link>
          </div>
        </div>
        <div className={s.footer__bottomPart}>
          <p
            className={s.footer__textPolicyMain}
            style={{ textAlign: isMobile ? 'center' : 'left' }}
          >
            Copyright © 2024 ART STUDIO DENTAL
          </p>
          <div className={s.footer__linkPart}>
            <Link
              className={s.footer__linkPolicy}
              style={{ marginBottom: '0px', justifyContent: 'center' }}
              to={`${basePath}/cookies`}
            >
              <span
                className={s.footer__textPolicy}
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                {t('cookiePolicy')}
              </span>
            </Link>
            {!isMobile && (
              <PiLineVertical
                size={24}
                color="var(--background-btn-second-color)"
              />
            )}
            <Link
              className={s.footer__linkPolicy}
              style={{ marginBottom: '0px', justifyContent: 'center' }}
              to={`${basePath}/privacy-policy`}
            >
              <span
                className={s.footer__textPolicy}
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                {t('Privacy Policy')}
              </span>
            </Link>
            {!isMobile && (
              <PiLineVertical
                size={24}
                color="var(--background-btn-second-color)"
              />
            )}
            <Link
              className={s.footer__linkPolicy}
              style={{ marginBottom: '0px', justifyContent: 'center' }}
              to={`${basePath}/legal-notice`}
            >
              <span
                className={s.footer__textPolicy}
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                {t('LEGAL NOTICE')}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
